body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    flex-grow: 1;
    min-width: 250px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.client-logo {
    width: 200px;
    height: 100px;
    margin: auto;
    padding: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.camera {
    margin: auto;
}

.setting-button {
    position: absolute;
    bottom: 0;
    right: 0;
}

.nav-button {
    width: 90%;
    display: inline-block
}

.b * {
    border: 1px solid black;
}
